import {ref} from "vue";

export function useCheckoutDetails() {

    const tag = 'details';

    const first_name = ref(null);
    const last_name = ref(null);
    const email = ref(null);
    const phone = ref(null);
    const notes = ref(null);

    const zipcode = ref(null);
    const number = ref(null);
    const extra = ref(null);
    const street = ref(null);
    const city = ref(null);

    const remember = ref(false);

    const createDefault = () => {
        localStorage.setItem(tag, JSON.stringify({
            first_name: null, last_name: null, email: null, phone: null, notes: null,
            zipcode: null, number: null, extra: null, street: null, city: null,
            remember: false,
        }));
    };

    const saveDetails = () => {
        localStorage.setItem(tag, JSON.stringify({
            first_name: first_name.value,
            last_name: last_name.value,
            email: email.value,
            phone: phone.value,
            notes: notes.value,

            zipcode: zipcode.value,
            number: number.value,
            extra: extra.value,
            street: street.value,
            city: city.value,

            remember: remember.value,
        }));
    };

    const loadDetails = () => {
        let details = localStorage.getItem(tag);

        if (details) {
            details = JSON.parse(details);

            first_name.value = details.first_name;
            last_name.value = details.last_name;
            email.value = details.email;
            phone.value = details.phone;
            notes.value = details.notes;

            zipcode.value = details.zipcode;
            number.value = details.number;
            extra.value = details.extra;
            street.value = details.street;
            city.value = details.city;

            remember.value = details.remember;
        }
    };

    return {
        first_name, last_name, email, phone, notes,
        zipcode, number, extra, street, city,
        remember,
        createDefault, saveDetails, loadDetails
    };
}
